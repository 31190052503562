<template>
  <div class="unusable-choices-validations__return-btn-wrapper">
    <div
      class="unusable-choices-validations__btn"
      role="button"
      tabindex="0"
      @click="handleAppFeatureClick()"
    >
      <img
        class="unusable-choices-validations__back-btn"
        src="@/assets/images/arrow-left.svg"
        alt="Return to Test Cases List"
      />
      <button
        class="unusable-choices-validations__return-btn"
      >
        Back to validations
      </button>
    </div>
  </div>
  <div class="unusable-choices-validations__wrapper full-height">
    <div class="unusable-choices-validations__validations">
      <unusable-choices-validations-table
        :table="table"
        :pagination="pagination"
        :validation-id="validationId"
        :search-term="searchTerm"
        :handle-search-term-change="handleSearchTermChange"
        :handle-pagination-change="handlePaginationChange"
        :fetch-unusable-choices-list="fetchUnusableChoicesList"
      >
      </unusable-choices-validations-table>
    </div>
    <div class="unusable-choices-validations__summary">
      <unusable-choices-summary-validations
        :summary-data="summaryData"
        :total-elements="pagination.totalElements || table.rows.length"
        :is-export-loading="isExcelLoading"
        :on-export-click="onExportClick"
      ></unusable-choices-summary-validations>
    </div>
  </div>

  <mi-inner-loading class="bg-white" :showing="false">
    <mi-spinner size="70px"></mi-spinner>
    <h6 class="q-mt-lg q-mb-none"> Loading unusable choices. Please wait ... </h6>
  </mi-inner-loading>
</template>

<script>
  import { onMounted, reactive, ref, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { useRouteParams } from '@/composables/useRouteParams'
  import { exportFile } from 'quasar'
  import UnusableChoicesValidationsTable
    from '@/components/validations/test-cases/unusable-choices/UnusableChoicesValidationsTable.vue'
  import UnusableChoicesSummaryValidations
    from '@/components/validations/test-cases/unusable-choices/summary/Summary.vue'
  import { TEST_CASES_ROUTE } from '@/router/routeNames'
  import { useTable } from '@/composables/useTable'
  import { usePagination } from '@/composables/usePagination'
  import { unusableChoicesValidationsColumns } from '@/components/validations/test-cases/unusable-choices/utils'
  import { getValidationUnusableChoicesSummary, getValidationUnusableChoices, exportInvalidChoicesExcel } from '@/api'

  export default {
    name: 'UnusableChoices',
    components: { UnusableChoicesSummaryValidations, UnusableChoicesValidationsTable },
    setup() {
      const router = useRouter()
      const { routeParams } = useRouteParams()
      const { table, callbacks: { fetchTableData } } = useTable(unusableChoicesValidationsColumns)
      const { pagination, handleChange: handlePaginationChange } = usePagination({ pageSize: 20 })
      const summaryData = reactive({ value: {} })
      const searchTerm = ref('')
      const isExcelLoading = ref(false)

      onMounted(async () => {
        summaryData.value = await getValidationUnusableChoicesSummary(
          routeParams.value?.validationId
        )
      })

      const handleAppFeatureClick = () => {
        router.push({ name: TEST_CASES_ROUTE.name })
      }

      const buildUrlSearchParams = () => {
        const params = new URLSearchParams()
        searchTerm.value && params.append('search', searchTerm.value)
        params.append('page', pagination.currentPage - 1)
        params.append('pageSize', pagination.pageSize)
        return params.toString()
      }

      const fetchUnusableChoicesList = async () => {
        const params = buildUrlSearchParams()

        const fetchUnusableChoicesListPromise = () => getValidationUnusableChoices(
          routeParams.value?.validationId, params
        )
        const data = await fetchTableData(fetchUnusableChoicesListPromise)

        pagination.totalPages = data?.totalPages || 1
        pagination.totalElements = data?.totalElements
      }

      const handleSearchTermChange = value => {
        searchTerm.value = value
        pagination.currentPage > 1 ? pagination.currentPage = 1 : fetchUnusableChoicesList()
      }

      const onExportClick = (async () => {
        isExcelLoading.value = true
        try {
          const excelFile = await exportInvalidChoicesExcel(routeParams.value?.validationId)

          const businessName = summaryData.value.businessName || summaryData.value.userExportsName
          const filename = `Not_usable_choices_${ businessName }_${ summaryData.value.createdDate }.xlsx`

          exportFile(filename, new Blob([excelFile]))
        }
        catch (err) {
          throw new Error('Failed to download the unusable choices to an excel file')
        }
        finally {
          isExcelLoading.value = false
        }
      })

      watch(() => pagination.currentPage, () => {
        fetchUnusableChoicesList()
      })

      return {
        handleAppFeatureClick,
        table,
        fetchTableData,
        validationId: routeParams.value?.validationId,
        summaryData,
        fetchUnusableChoicesList,
        handlePaginationChange,
        pagination,
        searchTerm,
        handleSearchTermChange,
        buildUrlSearchParams,
        onExportClick,
        isExcelLoading
      }
    }
  }
</script>

<style lang="scss" scoped>

  .self-center {
    margin-left: 4%;
  }

  .row-border {
    border-bottom: 1px solid $mi-anthracite-50;
  }

  .row > .custom-col {
    width: 140px;
  }

  .unusable-choices-validations {
    &__btn {
      display: flex;
      align-items: center;
      gap: 11px;
      cursor: pointer;
    }

    &__wrapper {
      display: flex;
      height: 100%;
      flex-direction: row;
    }

    &__return-btn-wrapper {
      padding-left: 16px;
      height: 48px;
      width: 100%;
      background-color: #eaedf3;
      display: flex;
      align-items: center;
    }

    &__return-btn {
      color: $mi-anthracite-800;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
    }

    &__back-btn {
      width: 18px;
      height: 14px;
    }

    ::v-deep(&__return-btn:hover) {
      color: #000000;
    }

    &__validations {
      width: 80%;
      display: flex;
      flex-direction: column;
      max-height: 100%;

      &--header {
        .header-validation-name {
          margin-top: 0;
          font-size: 36px;
        }

        .header-title {
          margin-bottom: 0;
          color: $mi-red-500;
          font-size: 18px;
        }
      }

      &--content {
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        height: 70%;
        margin-right: 2%;
      }
    }

    &__summary {
      width: 20%;
    }
  }
</style>
